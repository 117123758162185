import React from 'react';
import Image from '@/components/elements/Image';
import SectionRichText from '../SectionRichText';
import { CareersPageImageSectionTypes } from './CareersPageImageSection.types';
import * as styles from './CareersPageImageSection.module.scss';

export default function CareersPageImageSection({
  body,
  topImage,
  middleLeftImage,
  middleRightImage,
  bottomImage,
}: CareersPageImageSectionTypes) {
  return (
    <div className="relative">
      <div className={styles.imagesWrapper}>
        <div className={styles.leftContainer}>
          <div className={styles.fakeGridItemStart} />
          {middleLeftImage && <Image fluid data={middleLeftImage} className={styles.middleLeftImage} />}
          <div className={styles.fakeGridItemEnd} />
        </div>
        <div className={styles.rightContainer}>
          {topImage && <Image fluid data={topImage} />}
          {middleRightImage && (
            <div className={styles.middleRightImage}>
              <Image fluid data={middleRightImage} />
            </div>
          )}
          {bottomImage && (
            <div className={styles.bottomImage}>
              <Image fluid data={bottomImage} />
            </div>
          )}
        </div>
      </div>
      <div className={styles.card}>
        <SectionRichText body={body} className={styles} />
      </div>
    </div>
  );
}
